import { useEffect } from 'react';
import localFont from 'next/font/local';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { withPasswordProtect } from 'next-password-protect';
import CssBaseline from '@mui/material/CssBaseline';
import { cn } from 'src/utils';
import CookieDrawer from 'components/CookieDrawer';
import PageShiftProgressBar from 'components/PageShiftProgressBar';
import WithBackdrop from 'components/WithBackdrop';
import isMaintenance from 'utils/isMaintenance';
import isProductionEnv from 'utils/isProductionEnv';
import { ColorModeContextProvider } from 'context/ColorModeContext';
import { CookieAnalytics } from 'lib/cookieAnalytics';
import { GtagScript } from 'lib/gtag';
import WithSnackbar from '../components/WithSnackbar';
import { AppWrapper } from '../store/AppProvider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@sjmc11/tourguidejs/src/scss/tour.scss'; // Styles
import '../theme/globals.css';
declare global {
  interface Window {
    cookie3: {
      trackEvent: (params: {
        category: string;
        action: string;
        name?: string;
        value?: number;
      }) => void;
    };
  }
}
const helvetica = localFont({
  src: [{
    path: './../fonts/Helvetica-Light.woff2',
    weight: '300',
    style: 'normal'
  }, {
    path: './../fonts/Helvetica.woff2',
    weight: '400',
    style: 'normal'
  }, {
    path: './../fonts/Helvetica-Italic.woff2',
    weight: '400',
    style: 'italic'
  }, {
    path: './../fonts/Helvetica-Medium.woff2',
    weight: '500',
    style: 'normal'
  }, {
    path: './../fonts/Helvetica-Bold.woff2',
    weight: '700',
    style: 'normal'
  }],
  variable: '--font-sans'
});
const MyApp = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}: {
  Component: any;
  pageProps: {
    session: any;
  };
}) => {
  const router = useRouter();
  useEffect(() => {
    if (router.pathname !== '/maintenance') isProductionEnv && isMaintenance && router.push('/maintenance');
  }, [router]);
  return <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap" rel="stylesheet" />
      </Head>
      <div className={cn(helvetica.variable, 'font-sans')}>
        <ColorModeContextProvider>
          <PageShiftProgressBar />
          <CssBaseline />
          <WithSnackbar>
            <AppWrapper>
              <WithBackdrop>
                <Component {...pageProps} />
                <CookieDrawer />
              </WithBackdrop>
            </AppWrapper>
          </WithSnackbar>
        </ColorModeContextProvider>
      </div>
      {isProductionEnv && <GtagScript />}
      {isProductionEnv && <CookieAnalytics />}
    </>;
};
export default isProductionEnv ? MyApp : withPasswordProtect(MyApp, {
  loginApiUrl: '/api/appLogin',
  checkApiUrl: '/api/appPasswordCheck'
});